import Img from "gatsby-image"
import React, {useEffect, useRef} from "react"
import styled from "styled-components"
import { Container } from "../components/utils"
import gsap from "gsap"



const Hero = ({ image, children, isHome }) => {
  let hero = useRef(null)
    useEffect(() => {
      gsap.from(hero, {
        x: -100,
        y: 100,
        opacity: 0,
        stagger: 0.2,
        duration: 0.8,
      });
    }, [])
  return (
    <StyledHero isHome={isHome} >
      <Img className="hero-image" fluid={image} />
      <Container>
        <HeroText isHome={isHome} ref={(el) => (hero = el)}>{children}</HeroText>
      </Container>
    </StyledHero>
  )
}

export default Hero

const StyledHero = styled.div`
  display: flex;
  background: ${props =>
    props.isHome ? props.theme.background2 : `transparent`};
  position: relative;
  box-sizing: border-box;
  .hero-image {
    position: absolute !important;
    left: 0;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    overflow: hidden;
    @media (min-width: 768px) {
      left: 40%;
      width: 60%;
    }
    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${props =>
        props.isHome
          ? `transparent
        linear-gradient(180deg, #ffffff89 0%, #80808000 100%) 0% 0% no-repeat
        padding-box;`
          : `linear-gradient(${props.theme.accent}88, ${props.theme.accent}88)`};
    }
  }
`
const HeroText = styled.div`
  position: relative;
  padding: 2rem;
  background: white;
  margin: ${props => (props.isHome ? `8rem 0 5rem 0` : `4rem 0 4rem 0`)};
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  @media (min-width: 768px) {
    padding: 3rem;
    width: 80%;
  }
  @media (min-width: 992px) {
    padding: 3rem;
    width: 55%;
  }
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: white;
  }
  ::after {
    content: "";
    position: absolute;
    top: 12px;
    left: -12px;
    width: 100%;
    height: 100%;
    z-index: -2;
    background: linear-gradient(
        45deg,
        #d9b427 25%,
        transparent 25%,
        transparent 50%,
        #d9b427 50%,
        #d9b427 75%,
        transparent 75%,
        transparent
      )
      bottom left;
    background-size: 5px 5px;
  }
  h1 {
    margin-bottom: 0.6rem;
  }
  p {
    margin-bottom: 1.5rem;
  }
`
